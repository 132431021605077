import React from 'react';
const Home = () => {
  return (
    <div id="layout-wrapper">


        <header id="page-topbar">
            <div className="navbar-header">
                <div className="d-flex">
                  
                    <div className="navbar-brand-box">
                        <a className="logo logo-dark">
                            <span className="logo-sm">
                                <img src="assets/images/brand/logo-light.png" alt="" height="22" />
                            </span>
                            <span className="logo-lg">
                                <img src="assets/images/brand/logo-light.png" alt="" height="17" />
                            </span>
                        </a>

                        <a className="logo logo-light">
                            <span className="logo-sm">
                                <img src="assets/images/brand/logo-light.png" alt="" height="7" />
                            </span>
                            <span className="logo-lg">
                                <img src="assets/images/brand/logo-light.png" alt="" height="30" />
                            </span>
                        </a>
                    </div>

                    <button type="button" className="btn btn-sm px-3 font-size-16 header-item waves-effect"
                        id="vertical-menu-btn">
                        <i className="fa fa-fw fa-bars"></i>
                    </button>

                   
                    <form className="app-search d-none d-lg-block">
                        <div className="position-relative">
                            <input type="text" className="form-control" placeholder="Search..." />
                            <span className="bx bx-search-alt"></span>
                        </div>
                    </form>

                </div>

                <div className="d-flex">

                    <div className="dropdown d-inline-block d-lg-none ms-2">
                        <button type="button" className="btn header-item noti-icon waves-effect"
                            id="page-header-search-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i className="mdi mdi-magnify"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                            aria-labelledby="page-header-search-dropdown">

                            <form className="p-3">
                                <div className="form-group m-0">
                                    <div className="input-group">
                                        <input type="text" className="form-control" placeholder="Search ..."
                                            aria-label="Recipient's username" />
                                        <div className="input-group-append">
                                            <button className="btn btn-primary" type="submit"><i
                                                    className="mdi mdi-magnify"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>


                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item noti-icon waves-effect"
                            id="page-header-notifications-dropdown" data-bs-toggle="dropdown" aria-haspopup="true"
                            aria-expanded="false">
                            <i className="bx bx-bell bx-tada"></i>
                            <span className="badge bg-danger rounded-pill">3</span>
                        </button>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                            aria-labelledby="page-header-notifications-dropdown">
                            <div className="p-3">
                                <div className="row align-items-center">
                                    <div className="col">
                                        <h6 className="m-0" key="t-notifications"> Notifications </h6>
                                    </div>
                                    <div className="col-auto">
                                        <a href="#!" className="small" key="t-view-all"> View All</a>
                                    </div>
                                </div>
                            </div>
                            <div data-simplebar >
                                <a  className="text-reset notification-item">
                                    <div className="d-flex">
                                        <div className="avatar-xs me-3">
                                            <span className="avatar-title bg-primary rounded-circle font-size-16">
                                                <i className="bx bx-cart"></i>
                                            </span>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 className="mb-1" key="t-your-order">Your order is placed</h6>
                                            <div className="font-size-12 text-muted">
                                                <p className="mb-1" key="t-grammer">If several languages coalesce the
                                                    grammar</p>
                                                <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span
                                                        key="t-min-ago">3 min ago</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a  className="text-reset notification-item">
                                    <div className="d-flex">
                                        <img src="assets/images/users/avatar-3.jpg"
                                            className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                        <div className="flex-grow-1">
                                            <h6 className="mb-1">James Lemire</h6>
                                            <div className="font-size-12 text-muted">
                                                <p className="mb-1" key="t-simplified">It will seem like simplified English.
                                                </p>
                                                <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span
                                                        key="t-hours-ago">1 hours ago</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                                <a  className="text-reset notification-item">
                                    <div className="d-flex">
                                        <div className="avatar-xs me-3">
                                            <span className="avatar-title bg-success rounded-circle font-size-16">
                                                <i className="bx bx-badge-check"></i>
                                            </span>
                                        </div>
                                        <div className="flex-grow-1">
                                            <h6 className="mb-1" key="t-shipped">Your item is shipped</h6>
                                            <div className="font-size-12 text-muted">
                                                <p className="mb-1" key="t-grammer">If several languages coalesce the
                                                    grammar</p>
                                                <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span
                                                        key="t-min-ago">3 min ago</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>

                                <a  className="text-reset notification-item">
                                    <div className="d-flex">
                                        <img src="assets/images/users/avatar-4.jpg"
                                            className="me-3 rounded-circle avatar-xs" alt="user-pic" />
                                        <div className="flex-grow-1">
                                            <h6 className="mb-1">Salena Layfield</h6>
                                            <div className="font-size-12 text-muted">
                                                <p className="mb-1" key="t-occidental">As a skeptical Cambridge friend of
                                                    mine occidental.</p>
                                                <p className="mb-0"><i className="mdi mdi-clock-outline"></i> <span
                                                        key="t-hours-ago">1 hours ago</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </a>
                            </div>
                            <div className="p-2 border-top d-grid">
                                <a className="btn btn-sm btn-link font-size-14 text-center" >
                                    <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">View
                                        More..</span>
                                </a>
                            </div>
                        </div>
                    </div>

                    <div className="dropdown d-inline-block">
                        <button type="button" className="btn header-item waves-effect" id="page-header-user-dropdown"
                            data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <img className="rounded-circle header-profile-user" src="assets/images/users/avatar-1.jpg"
                                alt="Header Avatar" />
                            <span className="d-none d-xl-inline-block ms-1" key="t-henry">Henry</span>
                            <i className="mdi mdi-chevron-down d-none d-xl-inline-block"></i>
                        </button>
                        <div className="dropdown-menu dropdown-menu-end">
                           
                            <a className="dropdown-item" href="#"><i className="bx bx-user font-size-16 align-middle me-1"></i>
                                <span key="t-profile">Profile</span></a>
                            <a className="dropdown-item" href="#"><i
                                    className="bx bx-wallet font-size-16 align-middle me-1"></i> <span key="t-my-wallet">My
                                    Wallet</span></a>
                            <a className="dropdown-item d-block" href="#"><span
                                    className="badge bg-success float-end">11</span><i
                                    className="bx bx-wrench font-size-16 align-middle me-1"></i> <span
                                    key="t-settings">Settings</span></a>
                            <a className="dropdown-item" href="#"><i
                                    className="bx bx-lock-open font-size-16 align-middle me-1"></i> <span
                                    key="t-lock-screen">Lock screen</span></a>
                            <div className="dropdown-divider"></div>
                            <a className="dropdown-item text-danger" href="#"><i
                                    className="bx bx-power-off font-size-16 align-middle me-1 text-danger"></i> <span
                                    key="t-logout">Logout</span></a>
                        </div>
                    </div>



                </div>
            </div>
        </header>

       
        <div className="vertical-menu">

            <div data-simplebar className="h-100">

            
                <div id="sidebar-menu">
                   
                    <ul className="metismenu list-unstyled" id="side-menu">


                       {/* <li>
                                <a  className="has-arrow waves-effect">
                                    <i className="bx bx-home-circle"></i>
                                    <span key="t-dashboards">Dashboards</span>
                                </a>
                                <ul className="sub-menu" aria-expanded="false">
                                    <li><a href="index.html" key="t-default">Default</a></li>
                                    <li><a href="dashboard-saas.html" key="t-saas">Saas</a></li>
                                    <li><a href="dashboard-crypto.html" key="t-crypto">Crypto</a></li>
                                    <li><a href="dashboard-blog.html" key="t-blog">Blog</a></li>
                                    <li><a href="dashboard-job.html"><span className="badge rounded-pill text-bg-success float-end" key="t-new">New</span> <span key="t-jobs">Jobs</span></a></li>
                                </ul>
                            </li>  */}


                   {/* <li className="menu-title" key="t-apps">Apps</li>  */}


                        <li>
                            <a href="#" className="waves-effect">
                                <i className="bx bx-chat"></i>
                                <span key="t-chat">Menu 1</span>
                            </a>
                        </li>

                        <li>
                            <a href="#" className="waves-effect">
                                <i className="bx bx-file"></i>
                                <span key="t-file-manager">Menu 2</span>
                            </a>
                        </li>

                    </ul>
                </div>
            </div>
        </div>

        <div className="main-content">

            <div className="page-content">
                <div className="container-fluid">

                   
                    <div className="row">
                        <div className="col-12">
                            <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                                <h4 className="mb-sm-0 font-size-18">Dashboard</h4>
                            </div>
                        </div>
                    </div>
                
                </div>
            </div>

            <footer className="footer">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-6">
                          
                        </div>
                        <div className="col-sm-6">
                            <div className="text-sm-end d-none d-sm-block">
                                © 2023 easystreetcapital.com
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </div>

    </div>
  );
};

export default Home;