import React from 'react';
import { useNavigate } from 'react-router-dom';
const Landing = (props) => {
    const navigate = useNavigate();

    console.log('props',props);
  return (
    <div style={{
        backgroundImage: 'url(assets/images/common-img/landingBg.jpg)',
        backgroundPosition: '50% 50%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      }}>
     <div className="account-pages my-3 landing_page">
            <div className="container wrapper">
                <div className="row justify-content-center login_box">
                    <div className="col-md-12 col-lg-12">
                        <div className="signin-header mb-4">
                            <img src="assets/images/brand/logo-light.png" className="signin-logo" />
                           <div className="xray-btn"> <span className="material-icons-outlined"> support_agent</span>(866) 828-0062</div>
                        </div>
                        <div className="card overflow-hidden">
                    
                            <div className="card-body text-center"> 
                                <div className="row landing_content"> 
                                    <div className="col-md-7"> 
                                           <img src="assets/images/landingGraphic.png" className="landing_logo" />
                     
                                    </div>

                                    <div className="col-md-5 hello_welcome"> 
                                            <div className="welcome"> 
                                                    <h1>Hello & Welcome,</h1>
                                                    <div className="d-flex">    
                                                           <button className="btn"  onClick={(e) => {navigate('login');}}><img src="assets/images/broker 1.svg" />New<br/> Broker<span>+</span></button>
                                                           <button className="btn"><img src="assets/images/borrower.svg" />New <br/>Borrower<span>+</span></button>
                                                   </div>
                                               </div>
                                               <div className="welcome returning_users"> 
                                                    <h1>Returning Users, Please Login</h1>
                                                    <p>Iam affiliated with Easy Street capital LLC as</p>
                                                    <div className="d-flex"> 
                                                           <button className="btn"><img src="assets/images/team-member.svg" />Team<br/> Member</button>
                                                           <button className="btn"><img src="assets/images/borrower.svg" />Borrower <br/>(Guarantor)</button>
                                                           <button className="btn"><img src="assets/images/broker 1.svg" />Broker<br/>(Correspondent)</button>
                                                   </div>
                                               </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mt-4 text-center">
                            <div>
                                <p>© {new Date().getFullYear()} easystreetcapital.com</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
</div>
  );
};

export default Landing;