
import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import Login from './components/Login';
import Home from './components/Home';
import Landing from './components/Landing';
import PropertyInfo from './components/PropertyInfo';

function App() {

  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Landing />} />
        <Route path="/login" exact element={<Login />} />
        <Route path="/home" exact element={<Home />} />
        <Route path="/propertyinfo" exact element={<PropertyInfo />} />
      </Routes>
    </Router>
  );
}

export default App;
