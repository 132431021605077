import React from 'react';
const PropertyInfo = () => {
  return (
    <div className="create_process">
        <div className="header">
            <div className="wrapper">
                <div className="logo">
                    <img src="assets/images/brand/logo-light.png" className="img" alt="" />
                </div>
                <a href="#" className="btn"> <span className="material-icons"> close </span>EXIT</a>
            </div>

        </div>
        <div className="content_wrp">
            <div className="steps">
                <div className="box bx_progress">
                    <span className="text">Property
                        Info</span>
                    <img src="assets/images/icons/home_work.svg" className="img" alt="" />
                </div>
                <div className="box">
                    <span className="text">Loan
                        Program</span>
                    <img src="assets/images/icons/web_stories.svg" className="img" alt="" />
                </div>
                <div className="box">
                    <span className="text">Deal
                        Info</span>
                    <img src="assets/images/icons/handshake.svg" className="img" alt="" />
                </div>
                <div className="box">
                    <span className="text">Strategy
                        Info</span>
                    <img src="assets/images/icons/emoji_objects.svg" className="img" alt="" />
                </div>
                <div className="box">
                    <span className="text">Borrower
                        Info</span>
                    <img src="assets/images/icons/location_away.svg" className="img" alt="" />
                </div>
                <div className="box">
                    <span className="text">Submit
                        App</span>
                    <img src="assets/images/icons/rocket_launch.svg" className="img" alt="" />
                </div>
            </div>
            <div className="content_blk">
                <div className="hed">
                    <h2 className="title">Property Info</h2>
                </div>
                <div className="cont">
                    <h3 className="feild_title">Are You Purchasing or Refinancing ?</h3>
                    <div className="btn-group">
                        <button type="button" className="cp_btn">Purchasing</button>
                        <button type="button" className="cp_btn">Refinancing</button>
                    </div>
                </div>
            </div>
        </div>
        <div className="cp_footer">
            <div className="wrapper">
                <p>© 2023 Easy Street Capital LLC .All Rights Reserved.</p>
                <ul>
                    <li><a href="">Privacy </a></li>
                    <li><a href=""> | </a></li>
                    <li><a href="">Terms of Use</a></li>
                </ul>
            </div>
        </div>
    </div>
  );
};

export default PropertyInfo;