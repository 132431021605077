import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
const Login = () => {
        const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [emailError, setEmailError] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const handleLogin = (e) => {
      e.preventDefault();
  
      // Reset error messages
      setEmailError('');
      setPasswordError('');
  
      // Simple email and password validation
      if (!email || !email.includes('@')) {
        setEmailError('Enter a valid email address');
        return;
      }
  
      if (!password || password.length < 6) {
        setPasswordError('Password must be at least 6 characters');
        return;
      }
  
      // If both email and password are valid, you can proceed with authentication logic here
      console.log('Email:', email);
      console.log('Password:', password);
    };

  return (
    <div className="account-pages my-5">
    <div className="container">
        <div className="row justify-content-center login_box">
            <div className="col-md-8 col-lg-6 col-xl-5">
                <div className="signin-header mb-4">
                    <img src="../assets/images/brand/logo-light.png" className="signin-logo" />
                   <a className="xray-btn"  onClick={() => {navigate('/');}}> <span className="material-symbols-outlined"> west </span>Back</a>
                </div>
                <div className="card overflow-hidden">
            
                    <div className="card-body text-center"> 
                        <p>Iam affiliated with Easy Street capital LLC as</p>
                        <h2>Team Member</h2>
                        <div className="icon-circle center-position">
                            <img src="../assets/images/icons/team-member-icon.svg" />
                        </div>
                        <div className="p-2">
                            <form className="form-horizontal" action="#">

                                <div className="mb-3">
                                    <label htmlFor="username" className="form-label">Username</label>
                                    <input type="text" className="form-control" id="username" placeholder="Enter username" value={email} onChange={(e) => setEmail(e.target.value)} />
                                    <p className="text-danger">{emailError}</p>
                                </div>
        
                                <div className="mb-3">
                                    <label className="form-label">Password</label>
                                    <div className="input-group auth-pass-inputgroup">
                                        <input className="form-control" placeholder="Enter password" aria-label="Password" aria-describedby="password-addon" type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                        <p className="text-danger">{passwordError}</p>
                                     
                                    </div>
                                </div>

                                <div className="form-check">
                                    <input className="form-check-input" type="checkbox" id="remember-check" />
                                    <label className="form-check-label" htmlFor="remember-check">
                                        Remember me
                                    </label>
                                </div>
                                <div className="btn_wrp">
                                    <button className="btn btn-primary waves-effect waves-light" type="submit"  onClick={(e) => handleLogin(e)}>Log In</button>
                                    <a href="auth-recoverpw.html" className="text-muted"><i className="mdi mdi-lock me-1"></i> Forgot your password?</a>
                                </div>
                            </form>
                        </div>
    
                    </div>
                </div>
                <div className="mt-4 text-center">
                    <div>
                        <p>© easystreetcapital.com</p>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
  );
};

export default Login;